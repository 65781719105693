// src/utils/GoogleAnalytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('GTM-PK8VFKP5'); // Replace with your actual Measurement ID
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

// utility/analyticsZ.js

/**
 * Safely triggers a Google Analytics 4 event.
 * @param {string} eventName - GA4 event name like 'view_item', 'add_to_cart', etc.
 * @param {object} params - Parameters for the event (must match GA4 expected format).
 */
export const trackEvent = (eventName, params) => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      window.gtag('event', eventName, params);
      console.log(`[GA4] Event sents: ${eventName}`, params);
    } else {
      console.warn(`[GA4] gtag is not defined. Cannot track: ${eventName}`);
    }
  };
  
  /**
   * Track when a product is viewed.
   * @param {object} product - Product details.
   */
  export const trackViewItem = (product) => {
   
    trackEvent('purchase', {
      currency: 'INR',
      value: 100,
      items: [
        {
          item_id: "jnjn",
          item_name: "nnkvner",
          price: 100,
          quantity:  1,
        },
      ],
    });
     console.log('Testing',"jjnvijnrijn");
  };
  
  /**
   * Track when a product is added to the cart.
   * @param {object} product - Product details.
   */
  export const trackAddToCart = (product) => {
    trackEvent('add_to_cart', {
      currency: 'INR',
      value:  1,
      items: [
        {
          item_id: 1,
          item_name: "bhkbrv",
          price: 1,
          quantity:  1,
        },
      ],
    });
  };
  
  /**
   * Track when checkout is started.
   * @param {array} cartItems - List of cart items.
   */
  export const trackBeginCheckout = (cartItems) => {
    const value = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    trackEvent('begin_checkout', {
      currency: 'INR',
      value: value,
      items: cartItems.map(item => ({
        item_id: item.id,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      })),
    });
  };
  
  /**
   * Track purchase completion.
   * @param {string} orderId - Unique order/transaction ID.
   * @param {array} cartItems - List of purchased items.
   * @param {number} totalValue - Total purchase value.
   */
  export const trackPurchase = (orderId, cartItems, totalValue) => {
    trackEvent('purchase', {
      transaction_id: orderId,
      currency: 'INR',
      value: totalValue,
      items: cartItems.map(item => ({
        item_id: item.id,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
      })),
    });
  };
  
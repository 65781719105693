// src/utils/ga4.js
import ReactGA from "react-ga4";

// ✅ Replace with your GA4 Measurement ID
const MEASUREMENT_ID = "G-TXEGEHND7V";

// Initialize GA4
export const initGA = () => {
  ReactGA.initialize(MEASUREMENT_ID, {
    debug_mode: true, // set to false in production
  });
};

// Track page views
export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Track eCommerce events
export const trackEvent = (eventName, eventParams) => {
  ReactGA.event(eventName, eventParams);
};


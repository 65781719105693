import "./styles/New.css";
import { CartContext } from "../Context/Ecomcontext";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "../Components/loader";
import axios from "axios";
import cartSection from "../Helper/Cart";
import logo1 from "../assets/img/logo.png";
import { toast } from "react-toastify";
import sec_img from "../assets/img/footer/paymant.png";
import sec_img1 from "../assets/img/footer/secure.png";
import { Modal } from "react-responsive-modal";
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
import { ScrolltoTop } from "../utility";
import { useNavigate } from "react-router-dom";
import HappyCustomer from "../Components/UI/happyCustomer";
import Contactus from "./Components/Contactus";
import ReactGA from 'react-ga4';

const baseurl = process.env.REACT_APP_BASE_URL;

const imgurl = process.env.REACT_APP_IMG_URL;

const clientId = '853298021623-1onas4fia5fe85tniua3h0rtbvbenu1v.apps.googleusercontent.com';
//const clientId ="395798341450-c8ob29ugjgct2q4mruqq7f7t26ft0eo0.apps.googleusercontent.com";
export const trackBeginCheckout = (cartItems) => {
  ReactGA.event("begin_checkout", {
    currency: "INR",
    value: cartItems.reduce((acc, item) => acc + item.amount * item.product_count, 0),
    items: cartItems.map(item => ({
      item_id: item.title,
      item_name: item.title,
      price: item.amount,
      quantity: item.product_count,
    })),
  });
};
export const trackPurchase = (order_Id, cartItems, totalAmount) => {
  ReactGA.event("purchase", {
    transaction_id: order_Id,
    value: totalAmount,
    currency: "USD",
    items: cartItems.map(item => ({
      item_id: item.product_id,
      item_name: item.title,
      price: item.amount,
      quantity: item.product_count,
      item_category: item.bed_type,
      item_variant: item.color,
      item_dimensions: item.dimension + " " + item.thickness,
    })),
  });
};

export default function Checkout() {
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [Titlestate, setTitleState] = useState('Contact Details')
  const [contactDetails, setContactdetails] = useState(true)
  const [signupData, setSignupData] = useState({ name: '', email: '', number: '' });

  let navigate = useNavigate();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });


  useEffect(() => {
    ScrolltoTop()
  }, [])

  //Google Login Area

  const onSuccess = async (res) => {
    toast.success("Login Success");
    setSignupData(prevState => ({
      ...prevState, // Spread the previous state to retain other keys
      name: res.profileObj.name, // Update the specific key with the new value
      email: res.profileObj.email, // Update the specific key with the new value
    }));

    const user_details = {
      uname: res.profileObj.name,
      email: res.profileObj.email,
    };
    localStorage.setItem("userInfo", JSON.stringify(user_details));

    setUserInfo(user_details);

    await registerCheckGoogle(res.profileObj.name, res.profileObj.email);
  };

  //TODO REGISTER With Google 
  const registerCheckGoogle = (nameG, emailG) => {
    const requestBody = {
      name: nameG,
      email: emailG,
    };

    axios
      .post(baseurl + "user/google_register", requestBody)
      .then(function (response) {
        if (response.data.ResponseCode === 200) {
          toast.success(response.data.ResponseMsg);
        } else {
          toast.error(response.data.ResponseMsg);
        }
      });
  };

  const onFailure = (err) => {
    console.log("failed:", err);
  };




  const [cart, setCart] = useContext(CartContext);
  var total = cart.reduce(
    (prev, next) => parseInt(prev) + parseInt(next.amount),
    0
  );

  var subtotal = cart.reduce(
    (prev, next) =>
      parseInt(prev) +
      parseInt(next.product_count) * parseInt(next.price_original),
    0
  );

  var product_count_all = cart.reduce(
    (prev, next) => parseInt(prev) + parseInt(next.product_count),
    0
  );

  useEffect(() => {
    trackBeginCheckout(cart);
  }, []);

  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const data = {
    countries: [
      {
        name: "TAMIL NADU",
        states: [
          {
            name: "TAMIL NADU",
            district: [
              "Ariyalur",
              "Chengalpattu",
              "Chennai",
              "Coimbatore",
              "Cuddalore",
              "Dharmapuri",
              "Dindigul",
              "Erode",
              "Kallakurichi",
              "Kanchipuram",
              "Kanyakumari",
              "Karur",
              "Krishnagiri",
              "Madurai",
              "Nagapattinam",
              "Namakkal",
              "Nilgiris",
              "Perambalur",
              "Pudukkottai",
              "Ramanathapuram",
              "Ranipet",
              "Salem",
              "Sivaganga",
              "Tenkasi",
              "Thanjavur",
              "Theni",
              "Thoothukudi(Tuticorin)",
              "Tiruchirappalli",
              "Tirunelveli",
              "Tirupathur",
              "Tiruppur",
              "Tiruvallur",
              "Tiruvannamalai",
              "Tiruvarur",
              "Vellore",
              "Viluppuram",
              "Virudhunagar",
            ],
          },
        ],
      },
      {
        name: "GOA",
        states: [
          {
            name: "GOA",
            district: ["North Goa", "South Goa"],
          },
        ],
      },

      {
        name: "PUDUCHERRY (UT)",
        states: [
          {
            name: "PUDUCHERRY (UT)",
            district: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
          },
        ],
      },
      {
        name: "KERALA",
        states: [
          {
            name: "KERALA",
            district: [
              "Kannur",
              "Kasaragod",
              "Kollam",
              "Kottayam",
              "Kozhikode",
              "Malappuram",
              "Palakkad",
              "Pathanamthitta",
              "Thiruvananthapuram",
              "Thrissur",
              "Wayanad",
            ],
          },
        ],
      },

      {
        name: "KARNATAKA",
        states: [
          {
            name: "KARNATAKA",
            district: [
              "Bagalkot",
              "Ballari (Bellary)",
              "Belagavi (Belgaum)",
              "Bengaluru (Bangalore) Rural",
              "Bengaluru (Bangalore) Urban",
              "Bidar",
              "Chamarajanagar",
              "Chikballapur",
              "Chikkamagaluru (Chikmagalur)",
              "Chitradurga",
              "Dakshina Kannada",
              "Davangere",
              "Dharwad",
              "Gadag",
              "Hassan",
              "Haveri",
              "Kalaburagi (Gulbarga)",
              "Kodagu",
              "Kolar",
              "Koppal",
              "Mandya",
              "Mysuru (Mysore)",
              "Raichur",
              "Ramanagara",
              "Shivamogga (Shimoga)",
              "Tumakuru (Tumkur)",
              "Udupi",
              "Uttara Kannada (Karwar)",
              "Vijayapura (Bijapur)",
              "Yadgir",
            ],
          },
        ],
      },
    ],
  };

  const availableState = data.countries.find((c) => c.name === selectedState);

  const availableCities = availableState?.states?.find(
    (s) => s.name === selectedState
  );

  useEffect(() => {
    var cart_data = sessionStorage.getItem("poppy-cart");
    setCart(JSON.parse(cart_data));
    subtotal = cart.reduce(
      (prev, next) =>
        parseInt(prev) +
        parseInt(next.product_count) * parseInt(next.price_original),
      0
    );
    total = cart.reduce(
      (prev, next) => parseInt(prev) + parseInt(next.amount),
      0
    );
    product_count_all = cart.reduce(
      (prev, next) => parseInt(prev) + parseInt(next.product_count),
      0
    );
  }, []);

  const removeProduct = () => {
    setCart(cartSection.removeCartall());
  };

  //LOAD SCRIPT

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //PAYMENT ON SUBMIT
  const onSubmit = async (data, e) => {
    data.firstName = signupData.name;
    data.mobile = signupData.mobile;
    data.email = signupData.email

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const tot = total * 100;

    const requestBody = {
      payment: tot,
    };
    const result = await axios.post(baseurl + "user/Payment", requestBody);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { amount, id: order_id, currency } = result.data;

    const user_information = JSON.stringify([
      {
        user_name: data.firstName,
        user_email: data.email,
        user_mobile: data.mobile,
        street: data.street,
        city: data.city,
        pincode: data.Pincode,
        state: selectedState,
        district: selectedCity,
        order_notes: data.notes,
        contry: data.Country,
        total: total,
      },
    ]);

   

   

    const options = {
      key: "rzp_live_MCn1tClDeCveBq", // Enter the Key ID generated from the Dashboard
      amount: tot.toString(),
      currency: currency,
      name: "POPPY INDIA.",
      description: " Transaction",
      image: { logo1 },
      order_id: order_id,
      handler: async function (response) {
        const cartItems = JSON.parse(sessionStorage.getItem("poppy-cart"));
        trackPurchase(order_id, cartItems, tot);
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          order_products: sessionStorage.getItem("poppy-cart"),
          user_information: user_information,
        };

        const result = await axios.post(baseurl + "user/success", data);

        const o_id = await result.data.orderc_id;

        if (result.data.msg === "success") {
          toast.success("Order Placed!");

          const requestBody = {
            order_id: o_id,
          };


          e.target.reset();
          const send = await axios
            .post(
              "https://admin.poppyindia.com/admin/order_mail",
              requestBody
            )
            .then(function (response) {
              removeProduct();
              // navigate('/success')
              window.location.href = "/success";
              // onOpenModal();
              ///  let url_redirect = "/order_track/" + o_id;

              // navigate(url_redirect);
              // window.location.href =
              //   "https://poppyindia.com/order_track/" + o_id;
            });
          //  navigate('/');
        }
      },
      prefill: {
        name: data.firstName,
        email: data.email,
        contact: data.mobile,
      },
      notes: {
        address: "karur",
      },
      theme: {
        color: "#a12877",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      {/* <div className="headingContent">
        <h4 style={{ color: "#992876" }}>Shipping details </h4>
      </div> */}
      <div className="tw-flex tw-mt-8 lg:tw-mt-0 xl:tw-mt-0 tw-flex-col tw-gap-5 tw-items-center tw-justify-center tw-py-10 tw-px-5 tw-bg-gray-100">
        <h2 className="tw-px-[4rem] tw-text-[25px] xl:tw-text-[30px] md:tw-text-auto lg:tw-text-auto xl:tw-text-auto py-3 tw-bg-[#982876] tw-rounded-full tw-text-white tw-text-center">8,00,000+ Happy Customers and Counting!
        </h2>
        <p className="text-center tw-text-[16px] sm:tw-text-[18px] lg:tw-text-[20px] xl:tw-text-[20px] tw-capitalize">People's Trusted Mattress Brand since 2012 </p>
      </div>
      <div className="Check-row">


        <div className="col-75">
          {Object.keys(userInfo).length === 0 && <div ><p> <span> <GoogleLogin
            clientId={clientId}
            render={(renderProps) => (
              <a style={{ color: "#992876", cursor: "pointer" }} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                Login with Google
              </a>
            )}
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
          /></span>  <span>/ Continue as Guest</span> </p></div>}
          <div className="tw-flex tw-justify-between tw-items-end">
            <h4 className="headingContent" style={{ color: "#992876" }}>
              {Titlestate}{" "}
            </h4>

            <div className="tw-mb-2 tw-flex tw-gap-3 tw-relative tw-z-0 ">
              <div className="tw-h-[3px]  tw-w-[70px] sm:tw-w-[80px] md:tw-w-[80px] lg:tw-w-[80px] xl:tw-w-[80px] tw-bg-black tw-absolute tw-right-[67px] sm:tw-right-[67px] md:tw-right-[70px] lg:tw-right-[70px] xl:tw-right-[70px] tw-top-[8px] tw-rounded-full -tw-z-1"></div>
              <div className="tw-flex  tw-gap-2 tw-items-center tw-flex-col tw-justify-center ">
                <div style={{ border: contactDetails ? '6px solid #982876' : 'none' }} className={`${contactDetails ? '' : 'tw-border-none'} tw-w-[10px] tw-h-[10px] tw-p-1   tw-rounded-full tw-flex tw-justify-center tw-items-center tw-relative tw-z-0`}>
                  <div className={`${contactDetails ? 'tw-hidden' : ''}`}>
                    <Tick className="tw-text-[#982876] tw-rotate-3" width={35} height={35} />
                  </div>
                </div>
                <p className={` tw-text-center ${contactDetails ? '' : 'tw-mt-2'}`}>Contact Details</p>
              </div>
              <div className="tw-flex tw-gap-2 tw-items-center  tw-flex-col tw-justify-center ">
                <div style={{ border: '6px solid #982876' }} className={` ${contactDetails ? 'tw-opacity-20' : ''} tw-w-[10px] tw-h-[10px] tw-p-1 tw-border tw-border-black  tw-rounded-full tw-relative tw-z-0`}></div>
                <p className={`${contactDetails ? 'tw-text-black/20' : ''} tw-text-center`}>Shipping Address</p>
              </div>
            </div>
            {/* <h4 className="headingContent" style={{ color: "#992876" }}>
             {contactDetails ? '1/2' : '2/2'}
          </h4> */}

          </div>
          <div className="Check-container">
            {contactDetails ?

              <ContactDetails stepChanger={setContactdetails} setTitle={setTitleState} signupdata={signupData} Handlersignupdata={setSignupData} /> :

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="Check-row">
                  <div className="col-50">
                    {/* <label for="fname">
                    Full Name{" "}
                    {errors.firstName && (
                      <span
                        style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                      >
                        Enter valid First Name
                      </span>
                    )}{" "}
                  </label>

                  <input
                    className="InputLayout"
                    type="text"
                    id="fname"
                    name="firstname"
                    placeholder="John M. Doe"
                    {...register("firstName", {
                      required: true,
                      maxLength: 30,
                    })}
                  /> */}

                    {/* <label for="email">
                    {" "}
                    Email{" "}
                    {errors.email && (
                      <span
                        style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                      >
                        Enter valid Email Address{" "}
                      </span>
                    )}
                  </label>
                  <input
                    className="InputLayout"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="john@example.com"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  /> */}

                    {/* <label for="email">
                    {" "}
                    Mobile Number{" "}
                    {errors.mobile && (
                      <span
                        style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                      >
                        Enter valid Mobile Number{" "}
                      </span>
                    )}
                  </label>

                  <input
                    className="InputLayout"
                    type="text"
                    id="cname"
                    name="mobile"
                    placeholder="+91 1234567890"
                    {...register("mobile", {
                      required: true,
                      maxLength: 10,
                    })}
                  /> */}

                    <label for="email">
                      {" "}
                      Street{" "}
                      {errors.street && (
                        <span
                          style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                        >
                          Enter street name{" "}
                        </span>
                      )}
                    </label>
                    <input
                      className="InputLayout"
                      type="text"
                      id="adr"
                      name="street"
                      placeholder="542 W. 15th Street"
                      {...register("street", {
                        required: true,
                      })}
                    />
                    <label for="email">
                      {" "}
                      City{" "}
                      {errors.city && (
                        <span
                          style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                        >
                          Enter city name{" "}
                        </span>
                      )}
                    </label>
                    <input
                      className="InputLayout"
                      type="text"
                      id="city"
                      name="city"
                      placeholder="KK City"
                      {...register("city", {
                        required: true,
                      })}
                    />
                    <label for="fname">State </label>

                    <select
                      style={{ height: 47 }}
                      id="country-state"
                      name="country-state"
                      className="InputLayout"
                      value={selectedState}
                      onChange={(e) => setSelectedState(e.target.value)}
                    >
                      <option value="AN">Select State</option>

                      {data.countries.map((value, key) => {
                        return (
                          <option value={value.name} key={key}>
                            {value.name}
                          </option>
                        );
                      })}
                    </select>
                    <label for="fname">District </label>

                    <select
                      style={{ height: 47 }}
                      className="InputLayout"
                      placeholder="City"
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                    >
                      <option selected hidden disabled>
                        Select District
                      </option>
                      {availableCities?.district.map((e, key) => {
                        return (
                          <option value={e.name} key={key}>
                            {e}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-50">

                    <label for="fname">
                      Country{" "}
                      {errors.Country && (
                        <span
                          style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                        >
                          Enter Country name
                        </span>
                      )}{" "}
                    </label>

                    <input
                      className="InputLayout"
                      type="text"
                      id="fname"
                      name="Country"
                      placeholder="India"
                      value="India"
                      {...register("Country", {
                        required: true,
                        maxLength: 30,
                      })}
                    />
                    <label for="fname">
                      Pin Code{" "}
                      {errors.firstName && (
                        <span
                          style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                        >
                          Enter valid Pin code
                        </span>
                      )}{" "}
                    </label>

                    <input
                      className="InputLayout"
                      type="text"
                      id="fname"
                      name="Pincode"
                      placeholder="123456"
                      {...register("Pincode", {
                        required: true,
                        maxLength: 30,
                      })}
                    />

                    <label for="fname">Order Notes </label>

                    <input
                      className="InputLayout"
                      type="text"
                      id="fname"
                      name="ordernotes"
                      placeholder="Order notes"
                      {...register("notes", {
                        required: false,
                      })}
                    />
                  </div>
                </div>

                <input type="submit" value={"PAY NOW"} className="btnChekcout" />
              </form>
            }
          </div>
        </div>
        <div className="col-25">
          <div className="tw-flex tw-gap-10 tw-items-center tw-justify-center md:tw-justify-start lg:tw-justify-start xl:tw-justify-start">
            <h4 className="headingContent" style={{ color: "#992876" }}>
              Order summary{" "}
            </h4>
            <i onClick={() => (navigate('/cart'))} className="fa fa-edit tw-mt-[70px] active:tw-opacity-50 md:tw-hidden lg:tw-hidden xl:tw-hidden" aria-hidden="true" style={{ fontSize: "20px" }}></i>
          </div>
          <div className="Check-containerRight">
            {/* <h5 style={{ color: "black" }}>Your's Cart Items</h5> */}

            {cart
              ? cart.map((ad) => (
                <>
                  <div className="Checkout-products ">
                    <div className="checkout-item-layout">
                      <img className="checkout-item-image" src={imgurl + ad.image} alt={ad.title} />
                      <div style={{ marginLeft: 20, marginTop: 4 }}>
                        <p style={{ fontSize: 18, color: "black" }}>
                          {ad.title} <br />
                          {ad.sub_devision != 0 && <span style={{ color: "#982876", fontSize: 10 }}> {ad.sub_devision}</span>}
                        </p>
                        <hr style={{ marginTop: 5, marginBottom: 2 }} />
                        <span
                          style={{
                            fontSize: 14,
                            marginTop: 5,
                            display: "flex",
                            gap: 30,
                          }}
                        >
                          {ad.bed_type} | {ad.dimension} | {ad.thickness}{" "}
                          <span> Qty - {ad.product_count}</span>
                        </span>
                        {/*  */}
                        {/* <div style={{display:"flex",gap:10,marginTop: 5}}>
                        <img src={imgurl + ad.image} alt={ad.title} style={{width:30,height:30,borderRadius:50,border:'1px solid rgba(0, 0, 0, 0.15)'}} /> */}
                        <span
                          style={{
                            display: "flex",
                            fontSize: 12,
                            marginTop: 5,
                          }}
                        >
                          <b>Selected Color - {ad.color}</b>
                        </span>
                        {/* </div> */}

                        <hr style={{ marginTop: 5, marginBottom: 2 }} />

                        <p style={{ fontSize: 11 }}>
                          M.R.P{" "}
                          <span
                            style={{
                              marginLeft: 10,
                              fontFamily: "system-ui",
                              textDecoration: "line-through",
                            }}
                          >
                            {" "}
                            <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                            {ad.price_original} /-
                          </span>{" "}
                          <span>{ad.percentage} %</span>{" "}
                          <span
                            style={{
                              fontSize: 16,
                              marginLeft: 20,
                              fontFamily: "system-ui",
                              fontWeight: 500,
                            }}
                          >
                            <b>
                              <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                              {parseInt(ad.amount)}
                            </b>
                            /-
                          </span>
                        </p>

                        <p
                          style={{
                            fontSize: 10,
                            fontStyle: "italic",
                            marginTop: 10,
                            border: "1px solid rgba(0, 0, 0, 0.15)",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          Free Gift - {ad.free}
                        </p>
                      </div>
                    </div>


                    {/* <div>
                      <span className="price" style={{ marginTop: 10 }}>
                        {" "}
                        ₹ {ad.amount} /-
                      </span>
                    </div> */}

                  </div>

                  <hr style={{ marginTop: 5, marginBottom: 2, border: 'dashed 0.8px #982876' }} />

                </>))
              : null}

            <hr />
            {/* <p>
              Total{" "}
              <span
                className="price"
                style={{
                  color: "black",
                  fontSize: 24,
                  fontFamily: "system-ui",
                }}
              >
                <b>₹ {total} /-</b>
              </span>
            </p> */}

            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: 16 }}>
                  {" "}
                  Price ({product_count_all} Items){" "}
                </p>
                <b style={{ fontSize: 16, fontFamily: "system-ui", }}> <i className="fa fa-inr" aria-hidden="true"></i> {parseInt(subtotal)} </b>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <p style={{ fontSize: 16 }}> Discount </p>
                <b style={{ fontSize: 16, fontFamily: "system-ui", }}>
                  <i className="fa fa-inr" aria-hidden="true"></i> {parseInt(subtotal) - parseInt(total)}{" "}
                </b>
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <p style={{ fontSize: 16 }}> Shipping </p>
                <b style={{ fontSize: 16 }}>Free </b>
              </div>
            </div>
            <hr />
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 15,
                }}
              >
                <p style={{ fontSize: 16 }}>
                  {" "}
                  Total Price
                  <br /> <small>Inclusive of taxes</small>{" "}
                </p>
                <b style={{ fontSize: 20, fontFamily: "system-ui", }}> <i className="fa fa-inr" aria-hidden="true"></i> {parseInt(total)} </b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="CheckoutImgContainer">
        <img
          // onClick={onOpenModal}
          style={{ maxWidth: "100%", paddingTop: 20, marginBottom: 20 }}
          src={sec_img1}
        // width="100%"
        />
      </div>

      <div className="tw-w-full tw-py-10 tw-bg-[#982876]">
        <div className="container container1 cont-container" >
          <div className="content_contact">
            <div><h3 style={{ textAlign: "center", color: 'white', fontSize: 18 }}>Worried about your Choice ?   <br />Let our experts assist you</h3></div>
            <div className="Cont-img" style={{ cursor: 'pointer' }}><img loading='lazy' src="/assests/images/svg/customer.svg" width={50} height={50} />
              <a href="tel:+91 9054848481" style={{ color: "white", marginLeft: 10 }}><p>+91 9054848481</p></a></div>
          </div>
        </div>
      </div>


      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlayAnimationIn: "customEnterOverlayAnimation",
          overlayAnimationOut: "customLeaveOverlayAnimation",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
        animationDuration={800}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background:
              'url("https://img1.picmix.com/output/stamp/thumb/5/3/3/4/1034335_985ee.gif")',
          }}
        >
          {" "}
          <img
            src={"https://i.gifer.com/7efs.gif"}
            style={{ width: "50%", height: "50%" }}
          />
          <h4 className="desktop-responisve">
            {" "}
            Great news ! Your Order has been Placed !
          </h4>
          <h4 align="center" className="mobile-responisve">
            {" "}
            Great news !<br /> Your Order has been Placed !
          </h4>
          <p
            align="center"
            style={{ width: "75%", fontSize: 14, lineHeight: "28px" }}
          >
            Thank you For your Purchase! You will receive an Order confirmation
            email with tracking details of your order.
          </p>
          <div className="block m-top1" style={{ marginTop: 10 }}>
            <div className="block-content">
              <div className="social-content">
                <h6 align="center">Follow us on</h6>
                <div id="social-block">
                  <div className="social">
                    <ul className="list-inline mb-0 justify-content-end">
                      <li className="list-inline-item mb-0">
                        <a
                          href="https://m.facebook.com/poppymattressindia/"
                          target="_blank"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li className="list-inline-item mb-0">
                        <a
                          href="https://www.instagram.com/poppymattress"
                          target="_blank"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>

                      <li className="list-inline-item mb-0">
                        <a
                          href="https://in.linkedin.com/company/poppymattress"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li className="list-inline-item mb-0">
                        <a
                          href="https://www.youtube.com/@poppymattress"
                          target="_blank"
                        >
                          <i className="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}



const ContactDetails = ({ stepChanger, setTitle, signupdata, Handlersignupdata }) => {

  const [Loader, setLoader] = useState(false);



  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {

    setValue('name', signupdata.name);
    setValue('email', signupdata.email);
  }, [signupdata])


  const onSubmit = async (data) => {

    Handlersignupdata(prevState => ({
      ...prevState, // Spread the previous state to retain other keys
      name: data.name, // Update the specific key with the new value
      email: data.email, // Update the specific key with the new value
      mobile: data.mobile, // Update the specific key with the new value
    }));

    var cart_data = sessionStorage.getItem("poppy-cart");
    var products = [];

    if (cart_data) {
      const parsed_cart_data = JSON.parse(cart_data);

      products = parsed_cart_data.map(product => {
        const { product_id, title, bed_type, thickness, dimension, product_count } = product;

        // Remove double quotes from the thickness value
        const thicknessWithoutQuotes = thickness.replace(/"/g, '');

        return { product_id, title, bed_type, thickness: thicknessWithoutQuotes, dimension, qty: product_count };
      });
    }

    data.cart = products;
    setLoader(true)
    const result = await axios.post(baseurl + "user/leads", data);
    if (result) {
      setLoader(false)
      setTitle('Shipping details')
      stepChanger(false);
    } else {
      setLoader(false)
    }




  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">

          <div className="col-50">
            <label for="fname">
              Full Name{" "}
              {errors.name && (
                <span
                  style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                >
                  Enter valid First Name
                </span>
              )}{" "}
            </label>

            <input
              className="InputLayout"
              type="text"
              id="fname"
              name="name"
              placeholder="John M. Doe"
              {...register("name", {
                required: true,
                maxLength: 30,
              })}
            />

            <label for="email">
              {" "}
              Email{" "}
              {errors.email && (
                <span
                  style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                >
                  Enter valid Email Address{" "}
                </span>
              )}
            </label>
            <input
              className="InputLayout"
              type="text"
              id="email"
              name="email"
              placeholder="john@example.com"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />

            <label for="email">
              {" "}
              Mobile Number{" "}
              {errors.mobile && (
                <span
                  style={{ color: "red", marginLeft: 20, fontSize: 10 }}
                >
                  {errors.mobile.message}{" "}
                </span>
              )}
            </label>

            <input
              className="InputLayout"
              type="text"
              id="cname"
              name="mobile"
              placeholder="+91 1234567890"
              {...register("mobile", {
                required: "Mobile number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Mobile number must be 10 digits"
                }
              }
              )}
            />
            <input type="submit" value={Loader ? 'Loading..' : "Submit"} className="btnChekcout" disabled={Loader} />

          </div>
        </div>

      </form>
    </>
  )
}



function Tick(props) {
  return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" {...props}><path d="M16.972 6.251c-.967-.538-2.185-.188-2.72.777l-3.713 6.682-2.125-2.125c-.781-.781-2.047-.781-2.828 0-.781.781-.781 2.047 0 2.828l4 4c.378.379.888.587 1.414.587l.277-.02c.621-.087 1.166-.46 1.471-1.009l5-9c.537-.966.189-2.183-.776-2.72z" /></svg>;
}
